<template>
  <div>
    <div class="searchBox">
      <div>
        <div class="">
          <!-- @input="SearchClick()" -->
          <el-input v-model="value" placeholder="搜地点" >
            <template>
              <el-button slot="append" @click="SearchClick()" icon="el-icon-search"></el-button>
            </template>
          </el-input>
        </div>
        <div v-if="SearchResultArray && SearchResultArray.length > 0" class="SearchResultDataClass">
          <div
              v-for="(item, index) in SearchResultArray"
              :key="index"
              class="ResultItem"
              @click="SearchItemClick(item)"
          >
            <i class="el-icon-location-outline"></i>
            <div :title="item.district + item.name">
              {{ item.district }}{{ item.name }}
            </div>
          </div>
          <div class="hide" @click="SearchCancel">收起</div>
        </div>
      </div>

    </div>
    <div id="map" style="width: 100%;height: 500px"></div>
    <div style="display: flex;margin-top: 10px;line-height: 44px">
      <div style="width: 90px;">地址信息：</div>
      <el-input v-model="address" style="width:300px;"></el-input>
<!--      <span>{{address}}</span>-->
      <el-button type="normal" style="position: absolute;right: 100px" @click="cancelAddress">取 消</el-button>
      <el-button type="primary" style="position: absolute;right: 20px" @click="chooseAddress">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "mapDialog",
  props:['form'],
  data(){
    return{
      value: '',
      address:'',
      lnglat:[],
      SearchResultArray:[],
      autoComplete:{},
    }
  },
  methods:{
    SearchCancel() {
      this.SearchResultArray = null
    },
    SearchItemClick(e) {
      this.map.setCenter([e.location.lng,e.location.lat])
      this.marker  = new AMap.Marker({
        position: [e.location.lng,e.location.lat],
        map: this.map
      });
      this.lnglat = [e.location.lng,e.location.lat]
      this.address = e.district+e.address+e.name
      this.map.add([this.marker])
      this.SearchResultArray = null
    },
    SearchClick() {
      if (this.value) {
        this.autoComplete.search(this.value, (status, result) => {
          this.SearchResultArray = result.tips
        })
      } else {
        this.SearchResultArray = []
      }
    },
    cancelAddress(){
      this.$emit('closeModal','','')
    },
    chooseAddress(){
      this.$emit('closeModal',this.address,this.lnglat)
    }
  },
  mounted() {
    console.log(this.form)
    // if(this.form.loca)
    if(this.form.locationCoord){
        this.lnglat[0] = Number(this.form.locationCoord.split(',')[0])
        this.lnglat[1] = Number(this.form.locationCoord.split(',')[1])
    }else{
      this.lnglat = null
    }
    if(this.form.location){
        this.address = this.form.location
    }
    AMap.plugin(["AMap.AutoComplete"],function(){
      let autoOptions = {};
    })
    let that = this
    that.map = new AMap.Map("map", {
      resizeEnable: true,
      center:this.lnglat,
      zoom: 14
    });
    that.marker = new AMap.Marker({
      position:that.lnglat,
      map: that.map
    })
    var geocoder
    AMap.plugin(["AMap.Geocoder"],function (){
      geocoder = new AMap.Geocoder({
        radius: 500
      });
    })
    that.map.on('click',function(e){
      that.map.remove([that.marker])
      let point =[]
      point[0] = e.lnglat.lng
      point[1] = e.lnglat.lat
      that.lnglat = point
      console.log(point)
      that.marker = new AMap.Marker({
        position: point,
        map: that.map
      });
      that.map.add([that.marker]);
      geocoder.getAddress(point, function(status, result) {
        if (status === 'complete'&&result.regeocode) {
          var address = result.regeocode.formattedAddress;
          that.address = address
        }else{
          console.log('根据经纬度查询地址失败')
        }
      });
    })
    AMap.plugin(['AMap.AutoComplete','AMap.PlaceSearch'],function(){
      var autoOptions = {
        input: "tipinput"
      };
      that.autoComplete= new AMap.AutoComplete(autoOptions)
      that.autoComplete.on('select',function(e){
        that.map.setCenter([e.poi.location.lng,e.poi.location.lat])
      })
    })
  }
}
</script>

<style scoped lang="scss">
.searchBox {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  transition: left 0.5s;
  .inputBox {
    width: 2.67rem;
    border-radius: 0.22rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color-skin);
    border: 1px solid var(--border-color-skin);
    span {
      line-height: 24px;
      padding-right: 0.18rem;
      font-size: 0.2rem;
      color: var(--font-b-color-skin);
      cursor: pointer;
    }
  }

  .hide {
    border-top: 1px solid var(--border-color-skin);
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: var(--font-b-color-skin);
    margin-top: 0.1rem;
  }
  .SearchResultDataClass {
    position: absolute;
    width: 288px;
    background: var(--background-a-color-skin);
    border: 1px solid var(--border-color-skin);
    color: var(--font-color-skin);
    margin-top: 0.1rem;
    padding-top: 0.1rem;
    z-index: 10;
    background: white;
  }
  .SearchIconClass {
    height: 100%;
    width: 49px;
    background-size: 100% 100%;
    float: right;
    cursor: pointer;
  }
  .ResultItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    i {
      margin-left: 0.2rem;
      margin-right: 0.1rem;
    }
  }
}
</style>
